import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Helmet title='404 not found' defer={false}></Helmet>
    <div id='main' className='alt'>
      <section id='one'>
        <div className='inner'>
          <h1>Whoops! That's not right? A 404.</h1>
          <h2>
            You just hit a path that doesn&#39;t exist... much of sadness.
          </h2>
          <ul className='actions'>
            <li>
              <Link
                to='/'
                className='button special big'
                aria-label='Go to home page'
              >
                Get Me Out Of Here!
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
